<template>
  <v-container fluid class="mt-1 mb-10 pb-10">
    <h3 class="mb-2">Review file headers mapped to IRS fields:</h3>
    <v-row class="mx-auto">
      <v-col cols="6">
        <v-row align="center">
          <p class="py-0 px-0 my-0">Select file types to see mappings:</p>
          <v-checkbox
            v-model="reviewFileList"
            label="AP"
            value="AP"
            class="pl-5"
          ></v-checkbox>
          <v-checkbox
            v-model="reviewFileList"
            label="MP"
            value="MP"
            class="pl-5"
          ></v-checkbox>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1"
        ><v-row align="center"
          ><v-btn
            id="edit"
            color="#1e5288"
            class="white--text"
            @click="$router.push('/colmap')"
            >Edit</v-btn
          ></v-row
        ></v-col
      >
    </v-row>
    <v-row v-if="reviewFileList.includes('AP')" class="mt-5">
      <v-layout row wrap justify-end>
        <v-flex shrink>
          <v-col cols="6">
            <h3>AP&nbsp;Fields</h3>
          </v-col>
        </v-flex>
        <v-col cols="6">
          <h3>File Headers</h3>
        </v-col>
      </v-layout>
    </v-row>
    <v-row v-if="reviewFileList.includes('AP')">
      <v-list-item-content>
        <v-row v-for="(value, field) in currentAPMap" :key="field">
          <v-layout row justify-end>
            <v-flex shrink>
              <v-col class="mt-5">
                <v-list-item-title> {{ field }} </v-list-item-title>
              </v-col>
            </v-flex>
            <v-col cols="6" class="mt-5">
              <v-row>
                <v-col sm="auto" md="auto" lg="6"> {{ value }} </v-col>
              </v-row>
            </v-col>
          </v-layout>
        </v-row>
      </v-list-item-content>
    </v-row>
    <v-row v-if="reviewFileList.includes('MP')" class="mt-5">
      <v-layout row wrap justify-end>
        <v-flex shrink>
          <v-col cols="6">
            <h3>MP&nbsp;Fields</h3>
          </v-col>
        </v-flex>
        <v-col cols="6">
          <h3>File Headers</h3>
        </v-col>
      </v-layout>
    </v-row>
    <v-row v-if="reviewFileList.includes('MP')">
      <v-list-item-content>
        <v-row v-for="(value, field) in currentMPMap" :key="field">
          <v-layout row justify-end>
            <v-flex shrink>
              <v-col class="mt-5">
                <v-list-item-title> {{ field }} </v-list-item-title>
              </v-col>
            </v-flex>
            <v-col cols="6" class="mt-5">
              <v-row>
                <v-col sm="auto" md="auto" lg="6"> {{ value }} </v-col>
              </v-row>
            </v-col>
          </v-layout>
        </v-row>
      </v-list-item-content>
    </v-row>
    <v-row></v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    currentAPMap: {},
    currentMPMap: {},
    reviewFileList: [],
  }),
  beforeMount() {
    this.getIrsFields()
  },
  methods: {
    //getIrsFields sets the currentAPMap and currentMPMap dictionaries as the attributes from the APHeader or MPHeader model. These attributes are the values previously selected by the user to be the mapped values to the IRS fields.
    getIrsFields() {
      this.$api.get("/revcolmap").then((response) => {
        let data = JSON.parse(response.data)
        this.currentAPMap = data[0]
        this.currentMPMap = data[1]
      })
    },
  },
}
</script>
